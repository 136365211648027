import { useState } from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import Mintwidget from "./components/mintwidget";

function App() {
  const [accounts, setAccounts] = useState("");


  return (
    <div className="min-h-screen h-full w-full overflow-hidden flex flex-col items-center bg-gradient-to-b from-amber-500 to-lime-300">
        <Header accounts={accounts} setAccounts={setAccounts}/>
        <Mintwidget accounts={accounts} setAccounts={setAccounts}/>
        <Footer/>
    </div>
  );
}

export default App;
