import mtlxAbi from "../mtlxabi.json";
import { config } from "./dapp.config";
import { Buffer } from 'buffer/';   
window.Buffer = window.Buffer || Buffer; 
const { MerkleTree } = require("merkletreejs");
const whitelist = require("../whitelist.js");
const keccak256 = require("keccak256");
const { ethers } = require('ethers');
const provider = new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${config.INFURA_ID}`);
const mintProvider = new ethers.providers.Web3Provider(window.ethereum);
const signer = mintProvider.getSigner();
const contract = new ethers.Contract(config.contractAddress,mtlxAbi.abi, provider);
const mintContract = new ethers.Contract(config.contractAddress,mtlxAbi.abi, signer);

const leafNodes = whitelist.map((addr) => keccak256(addr));
const merkleTree = new MerkleTree(leafNodes, keccak256, { sortPairs: true });
const root = merkleTree.getRoot();


  //Whitelist mint function
  export const handleWLMint = async(mintAmount) => {
    if (!window.ethereum.selectedAddress) {
        return{
            success: false,
            status: "To be able to mint, you have to connect your wallet"
        }
    }

    const leaf = keccak256(window.ethereum.selectedAddress);
    const proof = merkleTree.getHexProof(leaf);
    const isValid = merkleTree.verify(proof, leaf, root);

    if (!isValid) {
        return {
          success: false,
          status: 'Invalid Merkle Proof - You are not on the whitelist'
        }
      }
    
      const price = config.price;

      try {
        const toPay = ethers.utils.parseEther((price * mintAmount).toString());
        const mint = await mintContract.whitelistMint(mintAmount, proof ,{ value: toPay });
        const receipt = await mint.wait();
        console.log("response: ", mint);
        const txHash = receipt.transactionHash;
        

        return{
            success: true,
            status: (
                <a href={`https://etherscan.io/tx/${txHash}`} target="_blank " rel="noreferrer">
                <p className="font-silkscreen text-center text-sm text-white">
                    {`✅ Check out your transaction on Etherscan: ${txHash}`}
                </p>
              </a>
            )
        }
         } catch (error) {
            return {
                success: false,
                status: (
                   <p className="font-silkscreen text-center text-sm text-white">
                    {`Something went wrong: ${error.message}`}
                   </p> 
                 ) 
         }
    }
  }

  //Public mint function
  export const handleMint = async(mintAmount) => {
    if (!window.ethereum.selectedAddress) {
        return{
            success: false,
            status: "To be able to mint, you have to connect your wallet"
        }
    }
        const price = config.price;
    
      try {
        const toPay = ethers.utils.parseEther((price * mintAmount).toString());
        const mint = await mintContract.publicMint(mintAmount, { value: toPay });
        const receipt = await mint.wait();
        console.log("response: ", mint);
        const txHash = receipt.transactionHash;

        return{
            success: true,
            status: (
                <a href={`https://etherscan.io/tx/${txHash}`} target="_blank " rel="noreferrer">
                <p className="font-silkscreen text-center text-sm text-white">
                    {`✅ Check out your transaction on Etherscan: ${txHash}`}
                </p>
              </a>
            )
        }
         } catch (error) {
            return {
                success: false,
                status: (
                   <p className="font-silkscreen text-center text-sm text-white">
                    {`Something went wrong: ${error.message}`}
                   </p> 
                 ) 
         }
    }
  }


export const getTotalMinted = async () => {
    const totalMinted = await contract.totalSupply()
    return ethers.utils.formatUnits(totalMinted,0)
} 

export const getPublicSale = async () => {
    const publicSaleState = await contract.isPublicSale()
    return publicSaleState
} 

export const getWhitelistSale = async () => {
    const whitelistSaleState = await contract.isWhitelistSale()
    return whitelistSaleState
} 

export const getMaxSupply = async () => {
    const maxSupply = await contract.maxSupply()
    return ethers.utils.formatUnits(maxSupply,0)
} 

export const getPrice = async ()  => {
    const price = await contract.price()
    return ethers.utils.formatUnits(price,1)
}




