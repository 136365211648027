import { useEffect } from "react";
import { config } from "../utils/dapp.config";
import twitter from "../images/twitter32.png";
import opensea from "../images/OpenSea32.png";
import etherscan from "../images/Etherscan32x32.png";


const Header = ({accounts, setAccounts}) => {
  const isConnected = Boolean(accounts[0]);

useEffect(() => {
  getConnectedWallet();
  addWalletListener();
})

  async function connectAccount() {
      if (window.ethereum) {
          const accounts = await window.ethereum.request({
              method: "eth_requestAccounts",
          });
          setAccounts(accounts);
      }
  }

  async function getConnectedWallet() {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      try {
        /* Metamask is installed */
        const accounts = await window.ethereum.request({
          method: "eth_accounts",
        });
        if (accounts.length > 0) {
          setAccounts(accounts[0]);
        } else {
          console.log("Please connect your Metamask");
        }
      } catch (err) {
        console.error(err.message);
      }
    } else {
      /* Metamask is not installed */
      console.log("Please install Metamask");
    }
  }

  async function addWalletListener() {
    if (typeof window != "undefined" && typeof window.ethereum != "undefined") {
      window.ethereum.on("accountsChanged", (accounts) => {
        setAccounts(accounts[0]);
      });
    } else {
      /* Set default state */
      setAccounts("");
      console.log("Please install Metamask");
    }
  }

  /*
  useEffect(() => {
    const fetchEvents = async () => {
      getConnectedWallet();
      addWalletListener();
    };
    fetchEvents();
  }, []);
*/
return (
    <header className="py-4">
    <div className="container mx-auto flex items-center justify-between">
      <nav aria-label="Contact Menu">
        <ul className="flex flex-col md:flex-row items-center space-x-4 md:space-x-6">
          <li className="cursor-pointer text-white">
            <a
              href="https://twitter.com/MTLXxyz"
              target="_blank"
              rel="noreferrer"
            >
              <img src={twitter} alt="twitter" />
            </a>
          </li>
          <li className="cursor-pointer text-white">
            <a href="https://opensea.io/collection/metallicordinals" target="_blank" rel="noreferrer">
              <img src={opensea} alt="Opensea" />
            </a>
          </li>
          <li className="cursor-pointer text-white">
            <a href={`https://etherscan.io/address/${config.contractAddress}#readContract`} target="_blank" rel="noreferrer">
              <img src={etherscan} alt="Etherscan" />
            </a>
          </li>
        </ul>
      </nav>
      <div className="flex flex-col justify-end">

    </div>
    {isConnected ? (
            <button className="absolute font-silkscreen right-4 bg-amber-600 transition duration-200 ease-in-out font-chalk border-2 border-[rgba(0,0,0,1)] shadow-[0px_3px_0px_0px_rgba(0,0,0,1)] active:shadow-none px-4 py-2 rounded-md text-sm text-black tracking-wide">
              {accounts.toString().substring(0, 6)}...
              {accounts.toString().substring(38)}{" "}
            </button>
          ) : (
            <button
              className="absolute font-silkscreen right-4 bg-amber-600 transition duration-200 ease-in-out font-chalk border-2 border-[rgba(0,0,0,1)] shadow-[0px_3px_0px_0px_rgba(0,0,0,1)] active:shadow-none px-4 py-2 rounded-md text-sm text-black tracking-wide uppercase"
              onClick={connectAccount}
            >
              CONNECT WALLET
            </button>
          )}

    </div>
  </header>

)
};

export default Header;