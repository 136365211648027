module.exports = [
  '0x2cf84928261f655a47d04ec714d3bedf9375de46',
  '0xcc5b34901772d791069b069b5246222a62f7f16b',
  '0x7c58805e40d431d13c06fefa0e26695a47e06bc4',
  '0x18e8caea3411b0b8208aacaba9a77fb48eba5a5e',
  '0x48a6e2bfb98c1305b7c91a91369d470303c2d466',
  '0x46e880887dfb4d5702f7607cd0674a70734bfa5a',
  '0xd0e8060e52d8c23984f22ac899d18fc53aa8bcbd',
  '0x251ede8027c5fbb5d19d9ac9563b4ba0bc33ec36',
  '0x85667fe20280ed627243daf166555503bed11779',
  '0x6ad2a5e6113d47dc4826253b07ef1bdd2718f5b9',
  '0x664936a6aa22170221495f14450b891e7267ad0c',
  '0x3a5fc2e0d7df18108473d928fc9512250eee7846',
  '0x8f91aacaa67686744ae9a1316abdddb137e66493',
  '0xd1d21d4ca3d0b3f5d4fb8f98fabc7d70dafad5c9',
  '0xf56017a37208b53b712e55d4b496a872cd10457e',
  '0xa2f4eb6ec11dc49347807aaa8f9c841eaa663003',
  '0x80b9c8c776d582baf36aac01229d1603179a31f5',
  '0x0520d7cfeba58a8838195ae83d8050deea869fc7',
  '0xb3826c54e55ffed47c623261b8b76f870071f00d',
  '0x422b59b27742885dc687c4bfef9a2e15051b0b37',
  '0xc6696836c052882c1504746f23fcd494de8691f0',
  '0x70f754869f66874513722001cdfffd1b42182082',
  '0x9fcc3743e284b365019a65817055b81650faf338',
  '0xcbbb25be37f088b79477499ca9df4757a6ac52bb',
  '0x4a81b0e9cde958359417e6c39ed6d86e126cc967',
  '0x521084c233fb5103634b706bd8fb1dfad4d4f3c2',
  '0xb32ec9935f9131d7f3d0b3ad1c28ca60f828a395',
  '0x2b36100e02702b9011bc17ac1d451c3144d39d45',
  '0x14d3a6c57db180addac1053e8a8a1fc727816861',
  '0x8485867178fde14abad7ebfaedac46e50fa41288',
  '0x16d2462ccd6104536c2a2ee3bb1fd998be5c10a4',
  '0xcc5b34901772d791069b069b5246222a62f7f16b',
  '0x1f67e0bfc91cb6f696baf386784dab392697a5dc',
  '0x708ac82707b1b8ddcc0bb8c50c75c38e786cbece',
  '0xc2f3f03fd3b2e0ef7990013efe9f29296ffbd533',
  '0xa2f4eb6ec11dc49347807aaa8f9c841eaa663003',
  '0x9d5d7e52e1b0f413abce69b57440855ff0aa8c72',
  '0x2cf84928261f655a47d04ec714d3bedf9375de46',
  '0x50cc710a508016d200abda185f0ec1512d0f1f8c',
  '0x13943b9880e8df6cbb275f793f14881c67d5de70',
  '0x8485867178fde14abad7ebfaedac46e50fa41288',
  '0xe64b000402e308e5ebea46329d6bbff9eae65b1c',
  '0x4c0ccd9cc894c1cca6995b088c5886c9d0e3aab5',
  '0x20d66886333375814919235674e68c7df7504659',
  '0x212f78cf022e86b30fcbe28d10d05d038f6df6d7',
  '0x2b36100e02702b9011bc17ac1d451c3144d39d45',
  '0x3bc03767f3d8b0646ef856e7307df4e025b86609',
  '0x433f8cdd144e19c028dddc387b060ef184d79138',
  '0x439df508008ef51d4842eb740effb2c937a689fe',
  '0x5016d3c1640b32066678d204717b542fb97ca9c8',
  '0x5813bcbdddd28e88c974946f80371ad5cf48ca82',
  '0x664936a6aa22170221495f14450b891e7267ad0c',
  '0x675fa0c35aa0ba6039aa68c6ca2fc1c824dec82c',
  '0x72c9ccab0ceaa27a9a15c1ad6c8aa2a0903cadb0',
  '0x7591e2a70d16c528cf964e439ea251baaca3713d',
  '0x7ae0333ef29c68f93f4b39a77220bdf377a0df19',
  '0x7b623d073efd1d9d16136dc6b3d6cb0409b94dbf',
  '0x7c3208681015867cc088817688491a39411337cf',
  '0x83bf1f446f2d92f971b927d71820a6625702ccec',
  '0x8f91aacaa67686744ae9a1316abdddb137e66493',
  '0x95891562df04ddc3214858130dde22e2fafd5f03',
  '0xab2707521e7040e6aca96f8175963b43c9e728fd',
  '0xaf59c8e2b93f940ec54c76335dd925f9b2811b6e',
  '0xb32ec9935f9131d7f3d0b3ad1c28ca60f828a395',
  '0xbb7d7d0e4f0fe4831fe3d822e111a5747fd70bfe',
  '0xc7c00aa92eac7e4498f8557107f58cb64bb77069',
  '0xc7e930786ab44a6a8c79a67c229cef0ad5ade0aa',
  '0xd4773c50240758fab74c45a19dad0ecb41878944',
  '0xde1c99a60b405a9f1104274218075e7a2f8d256e',
  '0xe1acf8fc08519c046ad19416ee5db1cb798391a3',
  '0x0f8f1031b40ab59a3780851d4a91c9e687701b8d',
  '0xf4c8bbac79168559ccb22fbb8619f4a3b74dedb0',
  '0x1265cffa178fa471ac65388aa50b29dec2ad8746',
  '0x50aCaF11538DB63b5DCfDC198C424501EF7c577D',
  '0xc9b4E422Bc97DC073c917E2d01b9B3D3F269f467',
  '0x48a6E2bFB98c1305b7C91a91369d470303C2d466',
  '0xAF76Ef09c181C371206Ddae9734524C7A1144466',
  '0x205981319B9d9B92Cd1d472Ad1ECA271f33DFA57',
  '0x661219126f8a5D7A52AE87545DC200DA3ee68Ff6',
  '0x39770a0154553492D3F783656D4fDfEdeC1391E3',
  '0x5Abb08449C957bF6859A5EBe99d93C07B36532DB',
  '0xe05965F07000F2656074d5fe2009606C04B0195b',
  '0xcc455b5ba7997a90f32b8f181c82185bbf20de13'
  
];
