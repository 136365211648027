


const footer = () => {

return(
    <footer className="bg-lime-400 h-14 bottom-0 fixed flex w-full mt-12">
    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 align-middle">
      <div className="flex items-center font-silkscreen justify-between h-full">
        <div className="text-white text-md text-left mr-2">
          Copyright ©2023 MTLX
        </div>
        <div className="text-white text-sm text-right ml-2">
          Follow us on <a href="https://twitter.com/MTLXxyz"
                target="_blank"
                rel="noreferrer">Twitter</a>
        </div>
      </div>
    </div>
  </footer>
)
};

export default footer;