import { useState, useEffect } from "react";
import { ethers } from "ethers";
import { config } from "../utils/dapp.config";
import mtlxAbi from "../mtlxabi.json";
import logo from "../images/mtlx.gif"; 
import {
  getTotalMinted,
  getPublicSale,
  getWhitelistSale,
  getMaxSupply,
  handleWLMint,
  handleMint
} from "../utils/interact";
import { Buffer } from 'buffer/';   
window.Buffer = window.Buffer || Buffer; 



const Mintwidget = ({accounts, setAccounts}) => {
  const isConnected = Boolean(accounts[0]);
  const [maxMintAmount, setMaxMintAmount] = useState(2);
  const [mintAmount, setMintAmount] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);
  const [isPublicSale, setIsPublicSale] = useState(false);
  const [isWhitelistSale, setIsWhitelistSale] = useState(false);
  const [maxSupply, setMaxSupply] = useState(0);
  const [status, setStatus] = useState(null)
  const [isMinting, setIsMinting] = useState(false);
  const [price, getPrice] = useState(0);
  const [contract, setContract] = useState(null);

  useEffect(() => {
    const fetchEvents = async () => {
      setIsPublicSale(await getPublicSale());
      setIsWhitelistSale(await getWhitelistSale());
      setTotalMinted(await getTotalMinted());
      setMaxSupply(await getMaxSupply());
      setMintAmount(config.maxMintperWallet);
      setMaxMintAmount(config.maxMintperWallet);
      getPrice(config.price);
    };
    fetchEvents();
  }, []);


  useEffect(() => {
    const initializeContract = async () => {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const _contract = new ethers.Contract(
          config.contractAddress,
          mtlxAbi.abi,
          signer
        );
        setContract(_contract);
      } catch (err) {
        alert('Install Metamask to use this mintpage');
      }
    };
  
    initializeContract();
  }, []);

  // Oder einen Ladezustand rendern, solange das `contract`-Objekt initialisiert wird
  if (!contract) {
    return <div className="align-middle justify-center">
        <p className="text-md font-silkscreen flex items-center text-white">
            Your Browser has not installed Metamask <br/>
            Please install first and come back again
        </p>
        </div>;; 
  }

  if (typeof window !== 'undefined' && typeof window.Buffer === 'undefined') {
    window.Buffer = Buffer;
  }
  


  const presaleMintHandler = async () => {
    setIsMinting(true)

    const { success, status } = await handleWLMint(mintAmount)

    setStatus({
      success,
      message: status
    })

    setIsMinting(false)
  }

  const publicMintHandler = async () => {
    setIsMinting(true)

    const { success, status } = await handleMint(mintAmount)

    setStatus({
      success,
      message: status
    })

    setIsMinting(false)
  }


  const incrementMintAmount = () => {
    if (mintAmount < maxMintAmount) {
      setMintAmount(mintAmount + 1);
    }
  };

  const decrementMintAmount = () => {
    if (mintAmount > 1) {
      setMintAmount(mintAmount - 1);
    }
  };


  return (
    <div className="relative w-full h-full flex flex-col items-center justify-center">
      <div className="container mx-auto w-1/2">
        <h4 className="text-white font-silkscreen text-5xl text-center my-6 animate-pulse">
          METALLIC ORDINALS
        </h4>
      </div>

      <div className="flex flex-col items-center justify-center h-full w-full px-2 md:px-10">
        <div className="relative z-1 md:max-w-3xl w-full bg-black py-4 rounded-md px-2 md:px-10 flex flex-col items-center">
          <h1 className="font-silkscreen uppercase text-3xl md:text-4xl text-white mt-3">
            {isWhitelistSale
              ? "Whitelist sale"
              : isPublicSale
              ? "Public sale"
              : "Sale is not active"}
          </h1>

          <div className="flex flex-col md:flex-row md:space-x-10 w-full mt-6 md:mt-6">
            <div className="relative w-full">
              <img
                src={logo}
                className="object-cover w-full sm:h-[290px] md:w-[290px] rounded-md md:m-1"
                alt="logo"
              />
            </div>

            <div className="flex flex-col items-center w-full px-4 mt-16 md:mt-0">
              <div className="w-full text-md font-silkscreen flex items-center justify-between text-white">
                <p>Mintprice ETH NFT</p>
                <div className="flex items-center space-x-3">
                  <p>22 $</p>
                </div>
              </div>
              <div className="w-full text-md font-silkscreen flex items-center justify-between text-white">
                <p>Mintprice Ordinal</p>
                <div className="flex items-center space-x-3">
                  <p>22 $</p>
                </div>
              </div>
              <div className="w-full text-md font-silkscreen flex items-center justify-between text-white">
                <p>Inscription cost</p>
                <div className="flex items-center space-x-3">
                  <p>33 $</p>
                </div>
              </div>
              <div className="w-full text-md font-silkscreen flex items-center justify-between text-white">
                <p>Total Cost</p>
                <div className="flex items-center space-x-3">
                  <p>77 $</p>
                </div>
              </div>

              <div className="border-t py-4 mt-6 w-full">
                <div className="w-full text-lg font-silkscreen flex items-center justify-between text-white">
                  <p>Minted amount</p>

                  <div className="flex font-silkscreen items-center space-x-3">
                    {totalMinted === maxSupply ? (
                      <p> SOLD OUT </p>
                    ) : (
                      <p>
                        {totalMinted} /{maxSupply}
                      </p>
                    )}
                  </div>
                </div>

                <div className="w-full text-lg font-silkscreen flex items-center justify-between text-white">
                  <p>Max per wallet</p>

                  <div className="flex items-center space-x-3">
                    <p>{maxMintAmount}</p>{" "}
                  </div>
                </div>

                <div className="w-full text-lg font-silkscreen flex items-center justify-between text-white mb-6">
                  <p>Price in eth </p>

                  <div className="flex items-center space-x-3">
                    <p>{price}</p> <span className="text-gray-400">+ GAS</span>
                  </div>
                </div>

                <div className="flex items-center justify-between border-t border-white pt-6">
                  <button
                    className="w-8 h-8 md:w-8 md:h-8 flex items-center justify-center text-brand-background hover:shadow-lg bg-white font-bold rounded-md"
                    onClick={decrementMintAmount}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 md:h-8 md:w-8"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M18 12H6"
                      />
                    </svg>
                  </button>
                  <p className="flex items-center justify-center flex-1 grow text-center font-bold text-orange-500 text-2xl font-silkscreen md:text-3xl">
                    {mintAmount}
                  </p>
                  <button
                    className="w-8 h-8 md:w-8 md:h-8 flex items-center justify-center text-brand-background hover:shadow-lg bg-white font-bold rounded-md"
                    onClick={incrementMintAmount}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 md:h-8 md:w-8"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                      />
                    </svg>
                  </button>
                </div>
              </div>
                
              {isConnected ? (
                totalMinted === maxSupply ? (
                  <button className="font-silkscreen mt-2 w-full bg-amber-600 px-6 py-3 rounded-md text-2xl text-white mx-4 tracking-wide uppercase cursor-not-allowed">
                    SOLD OUT
                  </button>
                ) : isWhitelistSale ? (
                  <button
                    className="font-silkscreen mt-2 w-full bg-amber-600 shadow-lg px-6 py-3 rounded-md text-2xl text-black  mx-4 tracking-wide uppercase"
                    onClick={presaleMintHandler}
                  >
                    {isMinting ? 'Minting...' : 'WHITELIST MINT'}
                  </button>
                ) : isPublicSale ? (
                  <button
                    className="font-silkscreen mt-2 w-full bg-amber-600 shadow-lg px-6 py-3 rounded-md text-2xl text-black  mx-4 tracking-wide uppercase"
                    onClick={publicMintHandler}
                  >
                    {" "}
                    {isMinting ? 'Minting...' : 'PUBLIC MINT'}
                  </button>
                ) : (
                  <button className="font-silkscreen mt-2 w-full bg-amber-600 px-6 py-3 rounded-md text-2xl text-white mx-4 tracking-wide uppercase cursor-not-allowed">
                    {" "}
                    SALE NOT LIVE
                  </button>
                )
              ) : (
                <button className="font-silkscreen mt-2 w-full bg-amber-600 shadow-lg px-6 py-3 rounded-md text-2xl text-black mx-4 tracking-wide uppercase disabled:opacity-25">
                  {" "}
                  CONNECT WALLET
                </button>
              )}
              
              <div>
                <p className="font-silkscreen text-white text-sm mt-2">
                  {" "}
                  You have to pay: {price * mintAmount} ETH + GAS
                </p>
              </div>
            </div>
          </div>
           
            {/* Status */}
             {status && (
              <div
                className={`border ${
                  status.success ? 'border-green-500' : 'border-brand-pink-400 '
                } rounded-md text-start h-full px-4 py-4 w-full mx-auto mt-8 md:mt-4"`}
              >
                <p className="flex flex-col space-y-2 text-white text-sm md:text-base break-words ...">
                  {status.message}
                </p>
              </div>
            )}

            {/* Contract Address */}
            <div className="border-t border-white flex flex-col items-center mt-6 py-2 w-full">
              <h3 className="font-silkscreen text-2xl text-white uppercase mt-6">
                Contract Address
              </h3>
              <a
                href={`https://etherscan.io/address/${config.contractAddress}#readContract`}
                target="_blank"
                rel="noopener noreferrer"
                className="text-white mt-4 font-silkscreen"
              >
                <span className="break-all ...">{config.contractAddress}</span>
              </a>
            </div>

        </div>
      </div>
    </div>
  );
};

export default Mintwidget;
